/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from 'react';
import {
	Table,
	DropdownButton,
	Dropdown,
	Form,
	Button,
	OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { FaListCheck } from "react-icons/fa6";
import { BsCheck, BsX } from 'react-icons/bs'; 
import LoadingTableBox from './LoadingTableBox';
import MessageBox from './MessageBox/MessageBox';
import { Link } from 'react-router-dom';
import ParamPagination from './ParamPagination/ParamPagination';
import moment from 'moment';
import { downloadExcel } from 'react-export-table-to-excel';
import { SCOPES, getRoleName } from '../permission-utils/scopes';
import ExportToExcelButton from './ExportData/ExportToExcel';
import StatusDropdown from './StatusDropDown/StatusDropdown';
import { IoTriangleOutline } from "react-icons/io5";
import "./DynamicListTable.css";


const DynamicListTable = ({
	dataName, //dataName to get the columns names
	data, //database
	loading,
	error,
	handleSort, //for central lists, sorting function
	direction,
	sortColumn,
	itemsPerPage, //for Paginator
	actionButtons, //seeDetails,editHandler,seeAttachment and deleteHandler
	showCheckboxColumn, //first column with checkboxes
	checkAllBoxes, //first row of first column with a check all
	links, //enable links
	frontPaginator, //frontPaginator
	exportOption, //export button, with modal
	siblingCount, //for Paginator,
	setPageSize,
	totalCount,
	onPageChange,
	currentPage,
	selectedItems,
	setSelectedItems,
	openProductModal = () => {}, // fuction to open edit/details product modal
	onChangeSelectedRows = () => {},
}) => {
	//front paginator


	
	//para variables inside variables
	function getNestedValue(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		for (const key of keys) {
			if (nestedValue && nestedValue[key] !== 'undefined') {
				nestedValue = nestedValue[key];
			} else {
				return null;
			}
		}
		return nestedValue;
	}

	//to show date in format (DD-MM-YYYY)
	const formatValue = (dataName, value, field = null, item = null) => {
		if (dataName === 'orders' && field === 'status') {
			const dropdownItemsMap = {
				'Cancelled': [],
				'Awaiting Confirmation': [
					{
						eventKey: 'confirm-order',
						label: 'Confirmar Pedido',
						onClick: () => actionButtons.confirmOrder(item),
					},
					{
						eventKey: 'cancel-order',
						label: 'Cancelar Orden',
						onClick: () => actionButtons.cancelOrder(item),
					}
				],
				'Pending Payment': [
					{
						eventKey: 'confirm-payment',
						label: 'Confirmar Pago',
						onClick: () => actionButtons.confirmPayment(item),
					},
					{
						eventKey: 'cancel-order',
						label: 'Cancelar Orden',
						onClick: () => actionButtons.cancelOrder(item),
					}
				],
				'Payment Confirmation': [
					{
						eventKey: 'confirm-payment',
						label: 'Confirmar Pago',
						onClick: () => actionButtons.confirmPayment(item),
					},
					{
						eventKey: 'cancel-order',
						label: 'Cancelar Orden',
						onClick: () => actionButtons.cancelOrder(item),
					}
				],
				'Pending Delivery': [
					{
						eventKey: 'confirm-delivery',
						label: 'Confirmar Entrega',
						onClick: () => actionButtons.confirmDelivery(item),
					},
					{
						eventKey: 'cancel-order',
						label: 'Cancelar Orden',
						onClick: () => actionButtons.cancelOrder(item),
					}
				]
			};

			const dropdownItems = dropdownItemsMap[value] || [];

			return <StatusDropdown status={value} dropdownItems={dropdownItems} isButton={true} item={item}/>;
		}
		if (dataName === 'users' && getRoleName(value)) {
			return getRoleName(value);
		}
		if (value === true) {
			return 'Si';
		} else if (value === false) {
			return 'No';
		}
		const isValidDateFormat = moment(
			value,
			'YYYY-MM-DDTHH:mm:ss.SSSZ',
			true,
		).isValid();
		if (isValidDateFormat) {
			return moment(value).format('DD-MM-YYYY');
		} else {
			return value;
		}
	};

	//lista de columnas con traducciones a mostrar
	const columnMap = {
		categories: {
			name: { label: 'Nombre', link: (item) => `Category/${item._id}` },
		},
		users: {
			name: { label: 'Nombre', link: false },
			email: { label: 'E-mail', link: false },
			role: { label: 'Rol', link: false },
			isVerified: { label: 'Verificado', link: false },
		},
		orders: {
			internalId: { label: 'ID', link: (item) => `/order/${item._id}` },
			'user.email': { label: 'Email', link: false },
			'shippingAddress.address': { label: 'Dirección', link: false },			
			'shippingAddress.telephone': { label: 'Teléfono', link: false },
			'shippingAddress.timeForDelivery': {
				label: 'Horario de entrega',
				link: false,
			},
			paymentMethod: { label: 'Método de pago', link: false },
			status: { label: 'Estado', link: false },
		},
		products: {
			name: { label: 'Nombre' },
			brand: { label: 'Marca', link: false },
			price: { label: 'Precio', link: false, isCurrency: true },
			'category.name': { label: 'Categoría', link: false },
			countInStock: { label: 'Stock', link: false },
			active: { label: 'Activo', link: false },
		},
		flaps:{
			'record.name': { label: 'Registro'},
			model: {label: 'Tipo'},
			field: {label: 'Campo'},
			currentValue: {label: 'Valor Actual'},
			newValue: {label: 'Nuevo Valor'},
			'requestedBy.name': {label: 'Solicitud'},

			// Registro | Tipo | Campo | Valor actual | Nuevo Valor | Botones aprobar y rechazar

			
		}
	};
	const columns = Object.keys(columnMap[dataName] || {});

	//checkboxes , se usa en el caso de alquileres
	const handleCheckAllBoxes = () => {
		if (!checkIfColumnIsFullySelected(data)) {
			selectAllItems();
		} else {
			removeSelectedProducts(data);
		}
	};

	const removeSelectedProducts = (products) => {
		const updatedItems = selectedItems.filter(
			(selectedItem) => !products.some((product) => product._id === selectedItem._id)
		);
		setSelectedItems(updatedItems);
		onChangeSelectedRows(updatedItems);
	};

	//to export
	const [selectedFields, setSelectedFields] = useState([]);
	const [exportName, setExportName] = useState('');
	const todayDate = moment().format('DD-MM-YYYY');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show, setShow] = useState(false);

	const handleDownloadExcel = () => {
		const selectedColumnMap = columnMap[dataName];
		const header = selectedFields.map((field) => selectedColumnMap[field].label);
		const body = data.map((item) =>
			selectedFields.map((field) =>
				formatValue(dataName, getNestedValue(item, field)),
			),
		);

		downloadExcel({
			fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
			sheet: 'react-export-table-to-excel',
			tablePayload: {
				header,
				body,
			},
		});
		handleClose();
	};

	//show two colums of checkboxes in modal
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columns.length / columnsPerRow) },
		(_, rowIndex) =>
			columns.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);
	const selectItemHandler = (item) => {
		const updatedItems = [...selectedItems, item];
		setSelectedItems(updatedItems);
		onChangeSelectedRows(updatedItems);
	};
	const selectAllItems = () => {
		const items = [...selectedItems, ...data];
		const uniqueData = items.filter(
			(item, index, self) => index === self.findIndex((p) => p._id === item._id)
		);

		setSelectedItems(uniqueData);
		onChangeSelectedRows(uniqueData);
	};
	const removeItemHandler = (item) => {
		const copiedItems = [...selectedItems];
		copiedItems.splice(
			copiedItems.findIndex(({ _id }) => item._id === _id),
			1,
		);
		setSelectedItems(copiedItems);
		onChangeSelectedRows(copiedItems);
	};
	const removeAllItems = () => {
		setSelectedItems([]);
		onChangeSelectedRows([]);
	};

	const checkRowHandler = (e, item) => {
		if (checkItemSelection(item)){ 
			removeItemHandler(item);
		}  else  {
			selectItemHandler(item);
		}
	};
	const checkItemSelection = (item) => selectedItems.some(i => i._id === item._id);

	const checkIfColumnIsFullySelected = (data) => {
		return data.every(product => checkItemSelection(product));
	};
	return (
		<>
			{exportOption ? (
				<ExportToExcelButton 
					data={data}
					dataName={dataName}
			
				/>
			
			) : (
				''
			)}
			<Table bordered  responsive size="sm" className='dynamic-list-table'>
				<thead>
					<tr>
						{showCheckboxColumn ? (
							<th className="checkbox-col">
								<div className='checkbox-container'>
								<Form.Check
									type={'checkbox'}
									id="allCheckboxes"
									checked={checkIfColumnIsFullySelected(data)}
									onClick={handleCheckAllBoxes}
									></Form.Check>
								</div>
							</th>
						) : (
							''
						)}
						{columns.map((column) => (
							<th
								key={column}
								onClick={frontPaginator && handleSort ? () => handleSort(column) : null}
							>
								{columnMap[dataName][column].label}
								{column === sortColumn && (
									<span>{direction === 'desc' ? <FaSortUp /> : <FaSortDown />}</span>
								)}
							</th>
						))}
						{actionButtons && dataName !== 'orders' && <th className="last-col actions-col text-center"></th>}
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan={5}>
								<LoadingTableBox loading={loading} />
							</td>
						</tr>
					) : error ? (
						<tr colSpan={5}>
							<td colSpan={5}>
								<MessageBox variant="danger">{error}</MessageBox>
							</td>
						</tr>
					) : data.length === 0 ? (
						<tr colSpan={10}>
							<td colSpan={10}>
								{/* <MessageBox variant="light">No hay resultados, si desea crear haga click <Link className="alert-link" to="/AdminScreen/newAccount">aquí</Link></MessageBox> */}
							</td>
						</tr>
					) : (
						data.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td className='checkbox-col'>
										<div className='checkbox-container'>
											<Form.Check
												type={'checkbox'}
												name={`checked${dataName}`}
												onChange={(e) => checkRowHandler(e, item)}
												value={item._id}
												id={`checked${dataName}`}
												checked={checkItemSelection(item)}
											/>
										</div>
									</td>
								)}
								{columns.map((column) => (
								 <td key={column} className = "col">
								 {links && columnMap[dataName][column].link ? (
									 dataName === 'products' && column === 'name' ? (
										 <Link onClick={() => columnMap[dataName][column].link(item)}>
											 {formatValue(dataName, getNestedValue(item, column))}
										 </Link>
									 ) : (
										 <Link to={columnMap[dataName][column].link(item)}>
											 {formatValue(dataName, getNestedValue(item, column))}
										 </Link>
									 )
								 ) : columnMap[dataName][column].isCurrency ? (
									 `$${formatValue(dataName, getNestedValue(item, column))}`
								 ) : (
									 formatValue(dataName, getNestedValue(item, column), column, item)
								 )}
							 </td>
								))}
								{actionButtons && dataName === 'flaps' && (
									<td className='flap-status-cell'>
										{item.status === 'Pending' ? (
			
											<div key={item._id} className="button-container">
				      <OverlayTrigger placement="top"  overlay={<Tooltip id={`tooltip-accept-${item._id}`}>Ver Solicitud</Tooltip>}>
													<Button
														variant="link"
														onClick={() => actionButtons.verifyHandler(item)}
														className="p-0 verify-btn"
													>
														<FaListCheck /> 
													</Button>
												</OverlayTrigger>
											</div>
										): (
											item.status === 'Approved'?
												(
													<>
														<BsCheck className="icon accept-icon" /> {item.confirmedBy?.name}
													</>
												) 
												: ( item.status === 'Rejected'? 
													<>
														<BsX className="icon reject-icon" />  {item.confirmedBy?.name}
													</>
													: null)
			
										) 
										}
									</td>
								)}
								{actionButtons && dataName !== 'orders' && dataName !== 'flaps' && (
									<td className='actions-col'>
										<div className="actions-container">
											<Dropdown>
												<Dropdown.Toggle as={IoTriangleOutline} id="dropdown-custom-1">
												</Dropdown.Toggle>
												<Dropdown.Menu className="details-menu">
													{actionButtons.seeDetails && (
														<div className='action'>
															<Dropdown.Item
																eventKey="1"
																onClick={() => actionButtons.seeDetails(item)}
															>
																Ver Detalles
															</Dropdown.Item>
														</div>
													)}
													{actionButtons.editHandler && (
														<div className='action'>
															<Dropdown.Item
																eventKey="2"
																onClick={() => actionButtons.editHandler(item)}
															>

																Editar
															</Dropdown.Item>
														</div>
													)}
													{actionButtons.resetPassword && (
														<div className='action'>
															<Dropdown.Item
																eventKey="2"
																onClick={() => actionButtons.resetPassword(item)}
															>

																Recuperar contraseña
															</Dropdown.Item>
														</div>
													)}
													{actionButtons.verifyUser && (
														<div className='action'>
															<Dropdown.Item
																eventKey="2"
																onClick={() => actionButtons.verifyUser(item)}
															>

																Verificar Usuario
															</Dropdown.Item>
														</div>
													)}

													{(actionButtons.seeAttachment && item.escritura) ||
														(item.comprobante && (
															<div className='action'>
																<Dropdown.Item
																	eventKey="3"
																	onClick={() => actionButtons.seeAttachment(item)}
																>

																	Ver Documento
																</Dropdown.Item>
															</div>
														))}
													{actionButtons.deleteHandler && (
														<div className='action'>
															<Dropdown.Item
																eventKey="3"
																onClick={() => actionButtons.deleteHandler(item)}
															>

																Eliminar
															</Dropdown.Item>
														</div>
													)}
													{actionButtons.previewHandler && (
														<div className='action'>
															<Dropdown.Item
																eventKey="4"
																onClick={() => actionButtons.previewHandler(item)}
															>

																Previsualizar
															</Dropdown.Item>
														</div>
													)}
													{actionButtons.editStockPriceHandler && (
														<div className='action'>
															<Dropdown.Item eventKey="5" onClick={() => actionButtons.editStockPriceHandler(item)}>
																Modificar precio/stock
															</Dropdown.Item>
														</div>
													)}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</td>
								)}
							</tr>
						))
					)}
				</tbody>
			</Table>
			<ParamPagination
				siblingCount={siblingCount}
				className="pagination-bar"
				totalCount={totalCount}
				onPageChange={onPageChange}
				currentPage={currentPage}
				pageSize={itemsPerPage}
				setPageSize={setPageSize}/>
		</>
	);
};

export default DynamicListTable;