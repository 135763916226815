import axios from '../restClient';
import { getParamsObj } from '../utils';

const BASE_URI = '/users';
class UserService {
	async edit(name, email, password) {
		const res = await axios.put(`${BASE_URI}/profile`, {
			name,
			email,
			password,
		});
		return res;
	}
	async editById(user) {
		const {_id, name, email, role} = user;
		const res = await axios.put(`${BASE_URI}/edit-by-id/${_id}`, {
			name,
			email,
			role,
		});
		return res;
	}
	async exists(email) {
		const res = await axios.get(`${BASE_URI}/user-exists?email=${email}`);
		return res;
	}
	async createGuest(email) {
		const res = await axios.post(`${BASE_URI}/guest`, {email});
		return res;
	}
	async list(searchParams, nonPaginated = false) {
		const query = getParamsObj(searchParams);
		if(nonPaginated) {
			query.nonPaginated = true;
		}
		const res = await axios.get(`${BASE_URI}`, {
			params: query
		});
		return res;
	}
	async forgotPassword(email) {
		const res = await axios.post(`${BASE_URI}/forgotPassword`, { email });
		return res;
	}
	async create(user) {
		const res = await axios.post(`${BASE_URI}`, user);
		return res;
	}
	async signup(name, email, password) {
		const uri = BASE_URI + '/signup';
		const res = await axios.post(uri, {
			name,
			email,
			password,
			role: 'client',
		});
		return res;
	}
	async resetPassword(token, password) {
		const res = await axios.put(`${BASE_URI}/resetPassword/${token}`, {
			password,
		});
		return res;
	}
	async verify(token) {
		const res = await axios.put(`${BASE_URI}/verifyUser/${token}`);
		return res;
	}
	async adminVerify(id) {
		const res = await axios.put(`${BASE_URI}/verifyUser/admin/${id}`);
		return res;
	}
	async delete(userId) {
    const res = await axios.delete(`${BASE_URI}/${userId}`);
    return res;
  }

	async getById(userId) {
    const res = await axios.get(`${BASE_URI}/${userId}`);
    return res;
  }
}
export default new UserService();
